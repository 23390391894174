import GraniteError from 'granite-admin/utils/granite-error';
import GraniteAPIClient from 'granite-admin/utils/granite-client';
import { endpoints } from './endpoints';
import { getAuthToken } from 'granite-admin/utils/auth-singleton';

const quiversMiddlewareUrl = process.env.REACT_APP_QUIVERS_MIDDLEWARE_URL;
const quiversBaseUrl = process.env.REACT_APP_QUIVERS_BASE_URL;
const quiversBaseUrlV2 = process.env.REACT_APP_QUIVERS_MIDDLEWARE_URL_V2

/* eslint-disable */

async function woocommercePlugin(params) {
  try {
    const { data } = await GraniteAPIClient(quiversMiddlewareUrl).post(`/woocommerce/pluginDataAPI`, params);
    return data;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

async function updateTimezone(business, payload) {
  try {
    const { data } = await GraniteAPIClient(quiversBaseUrl, {
      Authorization: getAuthToken(),
    }).post(`/updateTimers?business=${business}`, payload);
    return data;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

async function checkPluginActivated(business, id) {
  try {
    const data = await GraniteAPIClient(quiversBaseUrlV2).get(`/${endpoints.pluginStatus}?ref_id=${business}&plugin_id=${id}`)
    return data;
  } catch (err) {
    throw new GraniteError(err.errors)
  }
}
async function updatePluginActivated(business, id, payload) {
  try {
    const data = await GraniteAPIClient(quiversBaseUrlV2).post(`/${endpoints.pluginStatus}?ref_id=${business}&plugin_id=${id}`, payload)
    return data;
  } catch (err) {
    throw new GraniteError(err.errors)
  }
}

async function getOrderFetchList (base_url){
  try {
    const data = await GraniteAPIClient(base_url).get(`/${endpoints.getOrdersList}`)
    return data;
  } catch (err) {
    throw new GraniteError(err.errors)
  }
}


async function getOrderDashboardData(params) {
  try {
    const { data } = await GraniteAPIClient(quiversMiddlewareUrl, {
      Authorization: `apikey c577bec9-07e6-4b4f-920d-b505409a620a`,
    }).get(`get_stats`, {
      params: params,
    });
    return data;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

async function checkBusinessData(business,pageSize,pageNum) {
  try {
    const {data} = await GraniteAPIClient(quiversBaseUrl, {
      Authorization: getAuthToken(),
    }).get(`Search?business=${business}&searchTerm=EcommWooCommerce&pagesize=${pageSize}&page=${pageNum}`)
    return data;
  } catch (err) {
    throw new GraniteError(err.errors)
  }
}

async function startApplication(business,id){
  try{
    await GraniteAPIClient(quiversBaseUrl).post(`Activate?business=${business}`,{
      businessRefId:business,
      id:id
    })
  }catch(err){
    throw new GraniteError(err.errors)
  }
}

export default {
  woocommercePlugin,
  updateTimezone,
  checkPluginActivated,
  updatePluginActivated,
  getOrderFetchList,
  checkBusinessData,
  startApplication,
  getOrderDashboardData
};
