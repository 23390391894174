import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import NotFoundPage from 'granite-admin/core/components/NotFoundPage';
import useQuery from 'granite-admin/utils/useQuery';
import { getAuthToken, setAuthToken } from 'granite-admin/utils/auth-singleton';

const Login_redirect_URL = process.env.REACT_APP_QUIVERS_ADMIN_URL

const PrivateRoute = ({
  component: Component,
  layout,
  roles,
  breadcrumbPath,
  path,
  heading,
  actions,
  tabs,
  addTabBtn,
  permissions,
  location,
  noHeader,
  ...rest
}) => {
  let authToken = getAuthToken();

  const { query } = useQuery();
  // if (!authToken) {
  //   if (path === '/woocommerce/flowsetting') {
  //     window.location.href = Login_redirect_URL;
  //     return null;
  //   }
  //   return <NotFoundPage />;
  // }

  if (query.auth && query.auth !== authToken) {
    //to replace every space with + except 1st space
    authToken = `Bearer ${query.auth.substring(7).replaceAll(' ', '+')}`;
    setAuthToken(authToken);
  }

  if (!authToken) {
    if (path === '/shopify/app-redirect' || path === '/shopify/success' || path === '/shopify/invalid-request')
      window.open('https://admin.quivers.com/', '_self');
    else return <NotFoundPage />;
  }

  return (
    <>
      <Suspense fallback={() => <p>Loading</p>}>
        <Component {...rest} />;
      </Suspense>
    </>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
  roles: PropTypes.any,
  breadcrumbPath: PropTypes.any,
  path: PropTypes.any,
  heading: PropTypes.any,
  actions: PropTypes.any,
  tabs: PropTypes.any,
  addTabBtn: PropTypes.any,
  permissions: PropTypes.any,
  location: PropTypes.any,
  noHeader: PropTypes.bool,
};

export default PrivateRoute;
