// Lib Imports
import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Box, Text, Image } from 'grommet';
import useQuery from 'granite-admin/utils/useQuery';
import { useNavigate } from 'react-router-dom';
import Loader from 'granite-admin/core/components/Loader';
import quiversLogo from './assets/quivers.png';
import commonApi from 'common/gateways/common-api';
import wooCommerceApi from 'woocommerce/gateways/woocommerce-api';
import { setCookie } from 'granite-admin/utils/storage';


const redirectEnv = process.env.REACT_APP_REDIRECT_ENV;
const dashboard_URL = process.env.REACT_APP_REDIRECT_CHOOSE_BUSINESS;
const Redirect = () => {
  const navigate = useNavigate();
  const { query } = useQuery();
  const id = query.id;
  const business = query.business;
  const pluginV = query.pluginV;
  const wooV = query.wooV;
  const base_url = query.base_url;


  const handlePluginData = useCallback(async () => {
    try {
      const res = await commonApi.getData({ id, business });
      setCookie(id,base_url);
      const payloadBopis = {
        plugin_id: id,
        sandbox_mode: redirectEnv === 'prod' ? 'no' : 'yes',
        searchTerm: 'EcommWooCommerce',
        ecommercePlatformName: 'Woocommerce',
        static: res?.settings?.static,
        client_data: {
          base_url: base_url,
          consumer_key: res?.settings?.dynamic?.clientData?.consumer_key,
          consumer_secret: res?.settings?.dynamic?.clientData?.consumer_secret,
        },
      };
      await wooCommerceApi.woocommercePlugin(payloadBopis);

      window.location.href = `${dashboard_URL}${query?.business}/app_settings/${query?.id}?pluginRefId=EcommWooCommerce&tab=configure&version=${pluginV}`;

      // history.push(`/woocommerce?id=${query?.id}&business=${query?.business}&pluginRefId=EcommWooCommerce&tab=configure&version=${pluginV}`);

      // history.push(`/#/${query?.business}/app_settings/${query?.id}?pluginRefId=EcommWooCommerce&tab=configure&version=${pluginV}`);
    } catch (error) {
      console.error('Failed to get plugin data', error);
    }
  }, [business, id]);

  useEffect(() => {
    handlePluginData();
  }, [navigate, query]);

  return (
    <>
      <Box align="center" pad="large" direction="row" justify="center" background="white" gap="medium" height={'70%'}>
        <Image src={quiversLogo} height="80px" width="80px" />
        <Box direction="column">
          <Text size="xxlarge" weight={'bold'}>
            Please Wait....
          </Text>
          <Text size="large" weight={'bold'}>
            Redirecting to Quivers WooCommerce App
          </Text>
          <Text size="small" weight={'bold'}>
            Do not press back, reload or close the app
          </Text>
        </Box>
      </Box>
      <Box align="center" justify="center">
        <Loader />
      </Box>
    </>
  );
};

Redirect.propTypes = {
  // add your proptypes here
};

export default Redirect;
