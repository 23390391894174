// Lib Imports
import React from 'react';
import { Box, Heading, Paragraph, Button, ResponsiveContext } from 'grommet';
import { Download } from 'grommet-icons';

const DownloadApp = () => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box flex pad="small" align="center" justify="center" width="100%" background="light-2">
          <Box pad="small" width={size === 'small' ? '95%' : '80%'} margin={{ top: 'small' }}>
            <Heading
              level={2}
              margin={{ bottom: 'small' }}
              style={{ display: 'flex', justifyContent: 'center', width: '100%', maxWidth: 'none' }}
            >
              App Installation Instructions
            </Heading>
            <Paragraph size="small" margin={{ bottom: 'medium' }} textAlign="center">
              This application integrates your ecommerce platform with Quivers, syncing across orders, shipments,
              refunds, product data, and allowing inventory from your network on Quivers to be aggregated and synced to
              WooCommerce. You can use this app to manage your data flows for this integration. With it, you can turn
              any flow on or off and set the time interval for flow execution.
            </Paragraph>

            <Heading level={3} margin={{ top: 'medium', bottom: 'xsmall' }} textAlign="start">
              To Install manually and activate the plugin:
            </Heading>
            <Paragraph size="medium" textAlign="start">
              You need to install the Quivers plugin on your WordPress instance. Follow the steps below to fully
              activate and use the app:
            </Paragraph>

            {/* Step 1 */}
            <Box
              pad="small"
              width="100%"
              direction={size === 'small' ? 'column' : 'row'}
              align="center"
              justify="between"
              margin={{ vertical: 'small' }}
              border={{ color: 'light-4', size: 'small' }}
              round="xsmall"
              elevation="small"
            // gap="medium"
            >
              <Box width="100%">
                <Heading level={4} margin={{ bottom: 'small' }}>
                  Step 1: Download the Plugin
                </Heading>
                <Paragraph size="small" margin={{ bottom: 'small' }}>
                  Click the button to download the plugin's .zip file.
                </Paragraph>
              </Box>
              <Button
                icon={<Download />}
                label="Download Plugin"
                primary
                onClick={() => (window.location.href = 'https://quivers-public.s3.us-west-2.amazonaws.com/Woocommerce.v2/quivers.plugin.woocommerce.v2.V1.0.0.zip')}
                style={{ height: 'auto', maxWidth: '150px' }}
              />
            </Box>

            {/* Step 2 */}
            <Box
              pad="small"
              width="100%"
              margin={{ vertical: 'small' }}
              border={{ color: 'light-4', size: 'small' }}
              round="xsmall"
              elevation="small"
            >
              <Box>
                <Heading level={4} margin={{ bottom: 'small' }}>
                  Step 2: Access Your WordPress Admin Area
                </Heading>
                <Paragraph size="small" margin={{ bottom: 'small' }}>
                  Log in to your WordPress Admin Panel and navigate to <strong>Plugins → Add New</strong>.
                </Paragraph>
              </Box>
            </Box>

            {/* Step 3 */}
            <Box
              pad="small"
              width="100%"
              margin={{ vertical: 'small' }}
              border={{ color: 'light-4', size: 'small' }}
              round="xsmall"
              elevation="small"
            //   gap="medium"
            >
              <Box>
                <Heading level={4} margin={{ bottom: 'small' }}>
                  Step 3: Upload the Plugin
                </Heading>
                <Paragraph size="small" margin={{ bottom: 'small' }}>
                  Click the <strong>Upload Plugin</strong> button at the top of the screen. Select the downloaded .zip
                  file from Step 1. Click <strong>Install Now</strong> and wait for the installation to complete.
                </Paragraph>
              </Box>
            </Box>

            {/* Step 4 */}
            <Box
              pad="small"
              width="100%"
              margin={{ vertical: 'small' }}
              border={{ color: 'light-4', size: 'small' }}
              round="xsmall"
              elevation="small"
            //   gap="medium"
            >
              <Box>
                <Heading level={4} margin={{ bottom: 'small' }}>
                  Step 4: Activate the Plugin
                </Heading>
                <Paragraph size="small" margin={{ bottom: 'small' }}>
                  Once the installation is complete, click <strong>Activate Plugin</strong>.
                </Paragraph>
              </Box>
            </Box>

            {/* Step 5 */}
            <Box
              pad="small"
              width="100%"
              margin={{ vertical: 'small' }}
              border={{ color: 'light-4', size: 'small' }}
              round="xsmall"
              elevation="small"
            //   gap="medium"
            >
              <Box>
                <Heading level={4} margin={{ bottom: 'small' }}>
                  Step 5: Complete Quivers Configuration
                </Heading>
                <Paragraph size="small" margin={{ bottom: 'small' }}>
                  After activation, return to your Quivers configurations and complete the remaining settings. Once the
                  configuration is completed, the Quivers WooCommerce Plugin will be ready to use.
                </Paragraph>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default DownloadApp;
